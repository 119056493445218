import React from "react"
import Header from "./header"
import Footer from "../components/common/footer"
import { graphql } from "gatsby"
import Services from "../components/common/logipulseservices"
import { Helmet } from "react-helmet"
import { useTranslation, I18nextContext,Link } from "gatsby-plugin-react-i18next"

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
 const Products = () => {
  const { language } = React.useContext(I18nextContext)

  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/product/"
      : GATSBY_LANDING_PAGE_URL +`/${language}/product/`
  const canoncalUrl = buildCanonicalUrl();

  const buildHrefUrl = () =>
  language === "en"
    ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
    : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
const hrefUrl = buildHrefUrl();


  const { t } = useTranslation()
  return (
    <div className="Layout">
      <Header />
      <Services />
      <Footer />
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {t("Logistics and Freight Forwarding Solutions - Logipulse Products")}
        </title>
        <meta
          name="description"
          content={t("Logipulse products are designed to optimize and manage anything from quote to delivery in your supply chain. Streamline your supply chain operations with logistics and freight forwarding solutions of Logipulse.")}
        />
        <meta
          name="Keywords"
          content={t("logistics products, freight forwarding solutions and products")}         
        />

        <Link rel="canonical" href={canoncalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
      </Helmet>
    </div>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Products
