import React, { Component } from 'react';
import "../../styles/threeplwarehousing.scss";
import "../../styles/logipulseservices.scss"
import { Trans, I18nextContext, Link } from "gatsby-plugin-react-i18next";
import { withTranslation } from 'react-i18next';
import Photo from "../Photo"


class services extends Component {
  static contextType = I18nextContext;
  render() {
    return (
      <div>    
        <div className="banner-feature">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content-imgs">
                  <div className="pricings_in_details main-details" lang={I18nextContext._currentValue.language}>
                    <Link to="/"><Trans>HOME</Trans></Link>/ <span className="featuress"><Link to="/product/"><Trans>PRODUCTS</Trans></Link></span>
                  </div>
                  <div className="sub_main_contents" lang={I18nextContext._currentValue.language}>
                    <Trans>Simplifying your logistics</Trans>{" "}
                  </div>
                  <div className="threewarehouse_third_content" lang={I18nextContext._currentValue.language}>
                    <Trans>An easy workflow that is easy to learn and use</Trans>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="logipulse_sevices" lang={I18nextContext._currentValue.language}>
            <div className="row">

              <div className="col-sm-6 ">
                <h1 className="freight_heading" lang={I18nextContext._currentValue.language}><Trans>Freight Forwarding</Trans></h1>
                <p className="freight_contenting" lang={I18nextContext._currentValue.language}><Trans>Logipulse is your one stop answer when it comes to freight management software. With features like multilanguage, multilocation, multi-currency, a completely integrated finance module with operation modules and a clutter free and detailed analytical dashboard optimizing and managing anything from quote to delivery in your logistics is easy and at the click of a button.</Trans></p>

                <div className="freight_shodow_box_header" lang={I18nextContext._currentValue.language}>
                <div className="ico" lang={I18nextContext._currentValue.language}>
                  <Photo src="flight_black_24dp.svg" alt="Workflow and Approvals" />
                </div>
                <div className="freight_box-text" lang={I18nextContext._currentValue.language}><Link to="/product/air-freight-software"><Trans>Air Freight Forwarding</Trans></Link></div>                
              </div>

              <div className="freight_shodow_box_header" lang={I18nextContext._currentValue.language}>
                <div className="ico" lang={I18nextContext._currentValue.language}>
                  <Photo src="directions_boat_black_24dp.svg" alt="Workflow and Approvals" />
                </div>
                <div className="freight_box-text" lang={I18nextContext._currentValue.language}><Link to="/product/ocean-freight-software"><Trans>Ocean Freight Forwarding</Trans></Link></div>                
              </div>

              <div className="freight_shodow_box_header" lang={I18nextContext._currentValue.language}>
                <div className="ico" lang={I18nextContext._currentValue.language}>
                  <Photo src="local_shipping_black_24dp.svg" alt="Workflow and Approvals" />
                </div>
                <div className="freight_box-text" lang={I18nextContext._currentValue.language}><Link to="/product/land-freight-software"><Trans>Land Freight Forwaarding</Trans></Link></div>                
              </div>
              </div>

              <div className="col-sm-6">
                <h1 className="freight_heading" lang={I18nextContext._currentValue.language}><Trans>3PL Warehousing</Trans></h1>
                <p className="freight_contenting" lang={I18nextContext._currentValue.language}><Trans>Whether your goal is to automate your warehouses, or set a strong foundation with modern technology, Logipulse has all the answers for your unique needs. Our 2D view allows you an overview of your entire storage facility enabling space allocation as well as auto space calculation efficiently and in real time. Our software also supports a completely integrated finance module, latest inventory management with automatic stock updates and much more.</Trans></p>
                <div className="freight_know_more" lang={I18nextContext._currentValue.language}>
                                   <b>                                   
                                    <Link to="/product/warehouse-management-software/" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link>
                                  </b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    className="testing-arrow"
                                    alt="" lang={I18nextContext._currentValue.language}
                                  />
                                </div>
              </div>
            </div>
          </div>

          <div className="logipulse_sevices" lang={I18nextContext._currentValue.language}>
            <div className="row">
              <div className="col-sm-6 ">
                <h1 className="freight_heading" lang={I18nextContext._currentValue.language}><Trans>NVOCC</Trans></h1>
                <p className="freight_contenting" lang={I18nextContext._currentValue.language}><Trans>An NVOCC can be defined as “carrier for shippers” and a “shipper for carriers” and manages containers for shippers. Like shipments for a freight forwarder an NVOCC will manage your container space from origin port to destination port.</Trans> 
                                                  <Trans>Logipulse aids in keeping track of not only these voyages and cargo but also ensuring that the financial aspects of P&L statements as well as the profit margin for each leg of the voyage are kept updated. Managing your container flow is easier and hassle free.</Trans></p>
                <div className="freight_know_more" lang={I18nextContext._currentValue.language}>
                                   <b>
                                   
                                    <Link to="/product/nvocc-software" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link>
                                  </b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    className="testing-arrow"
                                    alt="" lang={I18nextContext._currentValue.language}
                                  />
                                </div>
              </div>
              <div className="col-sm-6">
                <h1 className="freight_heading" lang={I18nextContext._currentValue.language}><Trans>Movers & Relocation</Trans></h1>
                <p className="freight_contenting" lang={I18nextContext._currentValue.language}><Trans>Taking care of relocation requirements from a customer’s enquiry details to delivery. Paperless operations automate and integrate multiple functions, from the survey, packing, shipping, billing, accounting, vendor management and storage, into one well-organized platform.</Trans></p>
                <div className="freight_know_more" lang={I18nextContext._currentValue.language}>
                                   <b>
                                   
                                    <Link to="/" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link>
                                  </b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    className="testing-arrow"
                                    alt="" lang={I18nextContext._currentValue.language}
                                  />
                                </div>
              </div>
            </div>
          </div>


          <div className="logipulse_sevices" lang={I18nextContext._currentValue.language}>
            <div className="row">
              <div className="col-sm-6 ">
                <h1 className="freight_heading"lang={I18nextContext._currentValue.language}><Trans>Customs Clearance</Trans></h1>
                <p className="freight_contenting"lang={I18nextContext._currentValue.language}><Trans>All three modes of freight are featured with an easy and practical interface. Our customs clearance feature allows you to filter or search via AWB, BOE number, date of arrival or salesperson. Furthermore, easy attachment of documents, adding on charges which would auto integrate with the finance module makes this module an easy stop destination for all your customs clearance requirements.</Trans></p>
                <div className="freight_know_more"lang={I18nextContext._currentValue.language}>
                                   <b>
                                    
                                    <Link to="/product/customs-clearance-software" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link>
                                  </b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    className="testing-arrow"
                                    alt="" lang={I18nextContext._currentValue.language}
                                  />
                                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="end_divis_warehouse">
          <div className="header_contents_sections text-right"></div>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Photo
                  src="Bottom Image-11.svg"
                  className="innerimage"
                  alt=""
                />
              </div>

            </div>
          </div>
        </div>

      </div>
    )
  }
}
export default withTranslation()(services)

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;